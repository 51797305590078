import { GET } from '@/utils/http'

const PORT_FIRE = '/service-fire/stat'
const PORT_STAT = '/service-socialize-v2/stat'
const PORT_USER = '/service-users/'

// 消防力量统计
export function getPowerStatic(params) {
  return GET({ url: `${PORT_FIRE}/statFirePower`, params })
}

// 119接处警情趋势
export function getAlertStatic(params) {
  return GET({ url: `${PORT_FIRE}/alarmTrend`, params })
}

// 出警次数统计
export function getPoliceStatic(params) {
  return GET({ url: `${PORT_FIRE}/policeNumber`, params })
}

// 各区管辖用户管理统计
export function getManageStatic(params) {
  return GET({ url: `${PORT_USER}/userStatistics/sectorPic`, params })
}

// 预案统计
export function getUnitInfo(params) {
  return GET({ url: `${PORT_STAT}/plan`, params })
}

// 重点单位统计
export function getKeyUnitInfo(params) {
  return GET({ url: `${PORT_STAT}/keyUnitStat`, params })
}

// 底部消防力量展示
export function getOransInfo(params) {
  return GET({ url: `${PORT_FIRE}/statOrganFirePower`, params })
}
