<template>
  <div class="swiper-menu">
    <div class="plan-play-btn" @click="handlePlay">
      <div class="btn-prefix" />
      <p>进入推演</p>
      <div class="btn-prefix reverse" />
    </div>
    <i v-show="gorupList.length > showNum" class="swiper-btn left el-icon-caret-left" :class="{ 'btn-disaabled': currentNum === 1 }" @click="checkNum('prev')"></i>
    <i v-show="gorupList.length > showNum" class="swiper-btn right el-icon-caret-right" :class="{ 'btn-disaabled': currentNum === testNum - (showNum - 1) }" @click="checkNum('next')"></i>
    <div class="swiper-container" v-show="gorupList.length">
      <el-carousel ref="swiper" class="bottom-carousel" :class="{ 'align-center': true }" height="145px" :autoplay="false" :loop="false" indicator-position="none" arrow="never">
        <el-carousel-item v-for="(item, index) in gorupList" :key="index + 1">
          <div class="swiper-item" @click="handleClickUnit(item)">
            <p>{{ item.organName }}</p>
            <div class="info-item" v-for="(_item, _index) in item.detail" :key="_index">
              <img :src="_item.icon" />
              <p>{{ _item.value }}</p>
              <span>{{ _item.name }} {{ _item.unit }}</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { deepClone } from '@/utils/util'
import { getOransInfo } from '@/api/homePage'
import organIcon from '@/assets/images/bottom-menu/organ-icon.png'
import carIcon from '@/assets/images/bottom-menu/car-icon.png'
import peopleIcon from '@/assets/images/bottom-menu/people-icon.png'

const minWidth = 250
const initDetail = {
  organ: { name: '中队', unit: '（个）', icon: organIcon, value: undefined, show: true },
  car: { name: '车辆', unit: '（辆）', icon: carIcon, value: undefined, show: true },
  people: { name: '人员', unit: '（人）', icon: peopleIcon, value: undefined, show: true }
}

export default {
  data() {
    return {
      currentNum: 1,
      testNum: 5,
      showNum: 1,
      gorupList: []
    }
  },

  mounted() {
    this.showNum = 4
    this.initData().then(() => {
      this.checkOrganWidth()
    })
    this.initWidthListener()
  },

  watch: {
    showNum(val) {
      // 当前列队无法填满时重新计算当前展示
      if (this.testNum - this.currentNum >= val) return
      this.checkNum('', true)
    }
  },

  methods: {
    initWidthListener() {
      window.onresize = () => {
        this.showNum = Math.round((window.innerWidth - 880) / minWidth)
        this.checkOrganWidth()
      }
    },

    handlePlay() {
      const loading = this.$loading({
        lock: true,
        text: '进入推演',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$router.push({ name: 'Entrance' })

      setTimeout(() => {
        loading.close()
      }, 1500)
    },

    changeSwiper(action) {
      this.checkNum(action)
    },

    checkNum(action, reset = false) {
      const num = action === 'next' ? 1 : -1
      if (!reset) this.currentNum += num
      const remainNum = this.testNum + 1 - this.currentNum

      if (+remainNum < +this.showNum) {
        this.currentNum = this.testNum + 1 - this.showNum
      } else if (this.currentNum <= 0) {
        this.currentNum = 1
      }

      this.$refs?.swiper.setActiveItem(this.currentNum - 1)
    },

    initData() {
      return getOransInfo().then((res) => {
        res.forEach((item) => {
          const { carNum, organNum, peopleNum } = item
          item.detail = deepClone(initDetail)
          item.detail.car.value = carNum
          item.detail.organ.value = organNum
          item.detail.people.value = peopleNum
        })
        this.gorupList = res
      })
    },

    checkOrganWidth() {
      const swiper = this.$refs?.swiper.$el
      const viewNum = Math.min(this.showNum, this.gorupList.length)
      if (swiper) swiper.style.width = `${100 / viewNum}%`
    },

    handleClickUnit(item) {
      this.$emit('unitClick', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.swiper-menu {
  width: calc(100vw - 880px);
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.bottom-carousel {
  width: 25%;
}
::v-deep {
  .el-carousel {
    * {
      transition: unset;
    }
  }
  .bottom-carousel.el-carousel,
  .el-carousel__container {
    overflow-x: visible;
  }
  .el-carousel__item {
    box-sizing: border-box;
  }
}
.swiper-container {
  width: 100%;
  @include flex-center;
}

.swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  @include flex-center(center);
  height: 100%;
  font-size: 24px;
  color: #18cffd;

  &.left {
    left: -15px;
  }
  &.right {
    right: -20px;
  }
  &.btn-disaabled {
    color: #a6b1bf;
  }
}

.swiper-container {
  width: 100%;
  overflow: hidden;
}
.swiper-item {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
  width: calc(100% - 5px);
  height: 100%;
  background: rgba(2, 15, 33, 0.6);
  & > p {
    flex: 0 0 100%;
    height: 56px;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
    color: #18cffd;
  }
}
.info-item {
  height: 90px;
  flex: 1;
  @include flex-center(center, column);
  font-family: Source Han Sans CN;

  p {
    font-size: 20px;
    color: #8be6fc;
    line-height: 1;
    margin: 5px 0;
  }
  span {
    color: #c1cee1;
    font-size: 12px;
  }
}
.plan-play-btn {
  cursor: pointer;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);

  width: 120px;
  height: 42px;
  box-sizing: border-box;
  background: rgba(2, 15, 33, 0.8);
  border: 1px solid #1d95bb;
  border-left: none;
  border-right: none;
  @include flex-center(center);

  & > p {
    font-size: 20px;
    font-weight: bolder;
    @include gradientFont(linear-gradient(#00f2fe, #4facfe));
  }

  &::after,
  &::before {
    content: '';
    width: 28.3px;
    height: 28.3px;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    background: rgba(2, 15, 33, 0.8);
    border: 1px solid #1d95bb;
    position: absolute;
  }
  &::before {
    top: 5px;
    left: -15.15px;
    transform: rotate(45deg);
  }

  &::after {
    top: 5.3px;
    right: -15.15px;
    transform: rotate(-135deg);
  }

  &:hover {
    .btn-prefix {
      left: -44px;
      &.reverse {
        left: unset;
        right: -44px;
      }
    }
  }
}

.btn-prefix {
  position: absolute;
  top: 50%;
  left: -34px;
  transform: translateY(-50%);
  transition: all 0.3s;

  &.reverse {
    left: unset;
    right: -34px;
    &::before {
      transform: skewX(45deg);
    }
    &::after {
      transform: skewX(-45deg);
    }
  }

  &::before,
  &::after {
    content: '';
    @include size(12px);
    display: block;
    background: linear-gradient(to left, #00f2fe, #4facfe);
    transform: skewX(-45deg);
  }

  &::after {
    background: linear-gradient(to right, #4facfe, #00f2fe);
    transform: skewX(45deg);
  }
}

@media screen and (max-width: 1700px) {
  .bottom-carousel {
    width: 33.33%;
  }
}

@media screen and (max-width: 1500px) {
  .bottom-carousel {
    width: 50%;
  }
}
</style>
