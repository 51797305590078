<template>
  <div class="home">
    <top-navbar></top-navbar>
    <left-drawer></left-drawer>
    <right-drawer></right-drawer>
    <bottom-menu></bottom-menu>

    <center-map />
  </div>
</template>

<script>
import TopNavbar from './components/TopNavbar'
import LeftDrawer from './components/LeftDrawer'
import RightDrawer from './components/RightDrawer'
import BottomMenu from './components/BottomMenu'

import CenterMap from './components/CenterMap'

export default {
  name: 'Home',
  components: {
    TopNavbar,
    LeftDrawer,
    RightDrawer,
    BottomMenu,
    CenterMap
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  overflow: hidden;
  position: relative;
}
</style>
