<template>
  <div class="top-navbar">
    <div class="section section-left"></div>
    <div class="center-mask"></div>
    <div class="section section-center">
      <div class="navbar-title">
        <el-image :src="logoImg"></el-image>
        <h2>灭火救援沙盘推演系统</h2>
      </div>
    </div>
    <div class="section section-right">
      <el-popover trigger="hover" popper-class="dark-popover">
        <div class="user-info" v-if="userInfo">
          <p>{{ userInfo.organName }} - {{ userInfo.nickname }}</p>
          <p class="logout-btn" @click="handleLogOut"><i class="el-icon-switch-button"></i>退出</p>
        </div>
        <div slot="reference" class="avatar-container">
          <el-avatar src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" :size="30"></el-avatar>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import logoImg from '@/assets/images/navbar/login-logo.png'

export default {
  data() {
    return {
      logoImg
    }
  },

  computed: {
    ...mapState('user', ['userInfo'])
  },

  methods: {
    handleLogOut() {
      this.$confirm('确认退出系统？', '提示').then(() => this.logOut())
    },

    logOut() {
      this.$store.dispatch('user/logOut').then(() => {
        this.$message.success('退出成功')
        this.$router.replace({ name: 'login' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.top-navbar {
  width: 100vw;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
}
.section {
  box-sizing: border-box;
  height: 46px;
  background: rgba(1, 8, 14, 0.88);
  @include flex-center;
  border-bottom: 2px solid #009fff;
}
.section-left {
  flex: 0 0 calc(50vw - 200px);
  box-sizing: border-box;
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0), #009fff) 100 0;
}
.center-mask {
  width: 400px;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &::before,
  &::after {
    position: absolute;
    bottom: -2px;
    content: '';
    display: block;
    @include size(20px, 22px);
    width: 20px;
    height: 22px;
    background: transparent;
    border: 4px solid #18cffd;
    border-top: none;
  }

  &::before {
    left: 8px;
    border-right: none;
    transform: skewX(43deg);
  }

  &::after {
    right: 8px;
    border-left: none;
    transform: skewX(-43deg);
  }
}
.section-center {
  flex: 0 0 400px;
  height: 67px;
  clip-path: polygon(50% 0%, 100% 0, 100% 69%, 95% 100%, 5% 100%, 0% 69%, 0 0);
  position: relative;
}
.section-right {
  flex: 0 0 calc(50vw - 200px);
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 52px;
  border-image: linear-gradient(to left, rgba(0, 0, 0, 0), #009fff) 100 0;
}
.avatar-container {
  @include size(38px);
  @include flex-center(center);
  border-radius: 50%;
  border: 1px solid #18cffd;
  box-shadow: 0 0 5px 1px #18cffd;
}
.navbar-title {
  max-width: 400px;
  @include flex-center(center);
  font-family: Microsoft YaHei;
  margin: 0 auto;
  .el-image {
    width: 34px;
    height: 37px;
    margin-right: 14px;
  }

  h2 {
    font-weight: bolder;
    @include gradientFont(linear-gradient(#00f2fe, #4facfe));
  }
}
.user-info {
  color: #fff;
  font-size: 14px;

  .logout-btn {
    cursor: pointer;
    margin-top: 15px;
    font-weight: bolder;
    color: #18cffd;
    text-align: right;

    i {
      margin-right: 5px;
    }
  }
}
</style>
