<template>
  <div class="drag-drawer left-drawer" :class="{ 'drawer-hide': !drawerShown }">
    <div class="drag-dot" @click="handleToggleDrawer">
      <i :class="`el-icon-d-arrow-${drawerShown ? 'left' : 'right'}`"></i>
    </div>

    <div class="drawer-title">
      <p>
        <img src="@/assets/images/home-page-drawer/title-icon.png" />
        消防数据统计
        <img src="@/assets/images/home-page-drawer/title-icon-right.png" />
      </p>
    </div>

    <home-page-title title="消防力量"></home-page-title>
    <div class="power-info">
      <div class="info-item" v-for="(item, index) in powerInfo" :key="index">
        <p class="info-num">{{ item.value }}</p>
        <p>{{ item.title }}{{ item.unit }}</p>
      </div>
    </div>

    <home-page-title title="119接处警情趋势"></home-page-title>
    <alert-chart></alert-chart>

    <home-page-title title="各大队出警次数（最近一年）"></home-page-title>
    <action-record-chart></action-record-chart>
  </div>
</template>

<script>
import HomePageTitle from './HomePageTitle.vue'
import AlertChart from './AlertChart.vue'
import ActionRecordChart from './ActionRecordChart.vue'

import { getPowerStatic } from '@/api/homePage'
import drawerMixin from '../drawerMixin'
export default {
  components: {
    HomePageTitle,
    AlertChart,
    ActionRecordChart
  },

  mixins: [drawerMixin],

  data() {
    return {
      powerInfo: {
        organNum: { title: '消防队站', unit: '（个）', value: undefined },
        carNum: { title: '消防车辆', unit: '（辆）', value: undefined },
        peopleNum: { title: '消防人员', unit: '（人）', value: undefined }
      }
    }
  },

  mounted() {
    this.initPower()
  },

  methods: {
    initPower() {
      getPowerStatic().then((res) => {
        Object.keys(res).forEach((key) => {
          if (!this.powerInfo[key]) return
          this.powerInfo[key].value = res[key]
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/drawer';
.left-drawer {
  padding-left: 20px;

  background: linear-gradient(to right, #020f21, rgba(2, 15, 33, 0.5) 40%, rgba(0, 0, 0, 0) 80%);
  left: 0;
  transition: all 0.5s;
}
.drawer-hide {
  left: -450px;
}
.drag-dot {
  right: -71px;
}
.power-info {
  min-height: 82px;
  box-sizing: border-box;
  padding: 10px 0 40px;
  height: calc((100vh - 46px) * 0.2 - 66px);
}
</style>
