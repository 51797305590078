<template>
  <div class="drag-drawer right-drawer" :class="{ 'drawer-hide': !drawerShown }">
    <div class="drag-dot" @click="handleToggleDrawer">
      <i :class="`el-icon-d-arrow-${drawerShown ? 'right' : 'left'}`"></i>
    </div>

    <div class="drawer-title">
      <p>
        <img src="@/assets/images/home-page-drawer/title-icon.png" />
        重点单位情况
        <img src="@/assets/images/home-page-drawer/title-icon-right.png" />
      </p>
    </div>

    <div class="section-unit">
      <home-page-title reverse title="重点单位和预案统计"></home-page-title>
      <div class="power-info">
        <div class="info-item" v-for="(item, index) in unitInfo" :key="index">
          <p class="info-num">{{ item.value }}</p>
          <p>{{ item.title }}{{ item.unit }}</p>
        </div>
      </div>
      <div class="build-info">
        <div class="build-item" v-for="(item, index) in buildInfo" :key="index">
          <img :src="item.icon" />
          <p>{{ item.value }}</p>
          <span>{{ item.desc }}{{ item.unit }}</span>
        </div>
      </div>
    </div>

    <div class="section-chart">
      <home-page-title reverse title="各大队管辖分布"></home-page-title>
      <unit-chart></unit-chart>
    </div>
  </div>
</template>

<script>
import drawerMixin from '../drawerMixin'
import HomePageTitle from './HomePageTitle.vue'
import UnitChart from './UnitChart.vue'
import highBuildicon from '@/assets/images/home-page-drawer/high-build.png'
import hugeBuildicon from '@/assets/images/home-page-drawer/huge-build.png'
import petrochemicalBuildicon from '@/assets/images/home-page-drawer/petrochemical-build.png'
import underBuildicon from '@/assets/images/home-page-drawer/under-build.png'
import otherBuildicon from '@/assets/images/home-page-drawer/other-build.png'

import { getUnitInfo, getKeyUnitInfo } from '@/api/homePage'

const initUnitInfo = {
  keyUnit: { title: '重点单位', unit: '（家）', value: 0 },
  plan: { title: '消防预案', unit: '（家）', value: 0 },
  percent: { title: '预案完成率', value: '0%' }
}

const initBuildInfo = {
  highBuilding: { desc: '高层建筑', unit: '（家）', icon: highBuildicon, value: undefined },
  complex: { desc: '大型综合体', unit: '（家）', icon: hugeBuildicon, value: undefined },
  petrifaction: { desc: '石化企业', unit: '（家）', icon: petrochemicalBuildicon, value: undefined },
  undergroundSpace: { desc: '地下空间', unit: '（家）', icon: underBuildicon, value: undefined },
  other: { desc: '其他', unit: '（家）', icon: otherBuildicon, value: undefined }
}

export default {
  components: {
    HomePageTitle,
    UnitChart
  },

  mixins: [drawerMixin],

  data() {
    return {
      unitInfo: initUnitInfo,
      buildInfo: initBuildInfo
    }
  },

  mounted() {
    this.initInfo()
  },

  methods: {
    initInfo() {
      this.getUnitInfo()
      this.getKeyUnitInfo()
    },

    getUnitInfo() {
      getUnitInfo().then((res) => {
        const { unitNum, fireCaseNum } = res
        let percent = 0
        if (unitNum && fireCaseNum) {
          percent = fireCaseNum / unitNum * 100
          if (percent !== 100) percent = percent.toFixed(2)
        }

        this.unitInfo.keyUnit.value = unitNum
        this.unitInfo.plan.value = fireCaseNum
        this.unitInfo.percent.value = `${percent}%`
      })
    },

    getKeyUnitInfo() {
      getKeyUnitInfo().then((res) => {
        Object.keys(res).forEach((key) => {
          if (!this.buildInfo[key]) return
          this.buildInfo[key].value = res[key]
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/drawer';
.right-drawer {
  padding-right: 20px;

  background: linear-gradient(to left, #020f21, rgba(2, 15, 33, 0.5) 40%, rgba(0, 0, 0, 0) 80%);
  right: 0;
  transition: all 0.5s;
}

.drawer-hide {
  right: -450px;
}

.drag-dot {
  left: -71px;
}

.build-info {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 168px);
}

.build-item {
  flex: 0 0 33%;
  @include flex-center(center, column);
  padding: 16px 0;

  p {
    color: #8be6fc;
    font-weight: bolder;
    font-family: Source Han Sans CN;
    font-size: 20px;
    margin: 5px 0;
  }

  span {
    font-size: 12px;
    color: #c1cee1;
  }
}

.section-unit {
  height: 42vh;
  min-height: 370px;
}
</style>
