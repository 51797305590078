<template>
  <div class="linechart-container">
    <div v-show="chartEmpty" ref="lineChart" id="lineChart" class="loading-transparent" v-loading="loading"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

import { getAlertStatic } from '@/api/homePage'
import { deepClone } from '@/utils/util'

const charts = {
  lineX: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  unit: '接警次数（次）'
}

const lineY = [
  {
    name: '接警次数',
    type: 'line',
    color: 'rgb(24,207,253)',
    smooth: true,
    areaStyle: {
      normal: {
        color: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: 'rgba(24,207,253, 0.3)' },
            { offset: 0.8, color: 'rgba(24,207,253, 0)' }
          ]
        }
      }
    },
    symbol: 'circle',
    symbolSize: 5,
    data: []
  }
]

const option = {
  backgroundColor: 'transparent',
  tooltip: { trigger: 'axis' },
  grid: {
    top: '14%',
    left: '0',
    right: '10%',
    bottom: '10%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    data: charts.lineX,
    boundaryGap: true,
    axisLabel: {
      textStyle: { color: 'rgb(24 ,207, 253, 0.6)' },
      formatter: params => params.split(' ')[0]
    }
  },
  yAxis: {
    name: charts.unit,
    type: 'value',
    nameTextStyle: { align: 'left' },
    axisLabel: {
      formatter: '{value}',
      textStyle: { color: 'rgb(24 ,207, 253, 0.6)' }
    },
    splitLine: {
      lineStyle: { color: 'rgb(24,207,253,0.3)' }
    },
    axisLine: {
      lineStyle: { color: 'rgb(24 ,207, 253, 0.6)' }
    }
  },
  series: lineY
}

export default {
  data() {
    return {
      chartEmpty: true,
      loading: false
    }
  },

  mounted() {
    getAlertStatic().then((res) => {
      this.yAxis = res.valueList
      this.initChart()
    })
  },

  methods: {
    initChart() {
      this.alertChart = echarts.init(document.getElementById('lineChart'))
      const initOption = this.initChartOption()
      this.alertChart.setOption(initOption)
    },

    initChartOption() {
      const initOption = deepClone(option)
      initOption.series[0].data = this.yAxis
      return initOption
    }
  }
}
</script>

<style lang="scss" scoped>
#lineChart {
  width: 400px;
  min-height: 240px;
  height: calc((100vh - 46px) * 0.35 - 66px);
}
</style>
