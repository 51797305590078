export default {
  data() {
    return {
      drawerShown: true
    }
  },

  methods: {
    handleToggleDrawer() {
      this.drawerShown = !this.drawerShown
    }
  }
}
