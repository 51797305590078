<template>
  <div class="circle-chart-container">
    <div v-show="chartEmpty" ref="circleChart" id="circleChart" class="loading-transparent" v-loading="loading"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { deepClone } from '@/utils/util'
import { getPoliceStatic } from '@/api/homePage'

const colorList = ['#FC2D8A', '#B225F4', '#0292FE', '#03ACD1', '#04CAB7', '#03C781', '#FCE348']

const option = {
  legend: {
    type: 'scroll',
    orient: 'vertical',
    left: 0,
    top: 25,
    bottom: 20,
    itemWidth: 10,
    itemHeight: 10,
    textStyle: { color: '#C1CEE1' },
    data: []
  },
  tooltip: {
    trigger: 'item',
    position: 'top',
    backgroundColor: '#333',
    borderColor: '#333',
    textStyle: { color: '#fff' },
    formatter: '{b}：{d}%'
  },
  series: [
    {
      type: 'pie',
      center: ['50%', '50%'],
      radius: ['45%', '92%'],
      label: {
        show: false,
        position: 'center',
        formatter: ['{a|{c}}', '{b|出警次数 (次)}'].join('\n'),
        rich: {
          a: {
            color: '#18CFFD',
            fontSize: 26,
            fontWeight: 'bolder'
          },
          b: {
            color: '#C1CEE1',
            fontSize: 12
          }
        }
      },
      avoidLabelOverlap: false,
      itemStyle: {
        normal: {
          color: params => colorList[params.dataIndex % 7]
        }
      },
      animation: true,
      data: undefined,
      emphasis: {
        scale: true,
        scaleSize: 10,
        label: {
          show: true,
          fontSize: '11',
          color: '#C1CEE1'
        },
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}

export default {
  data() {
    return {
      chartEmpty: true,
      loading: false
    }
  },

  mounted() {
    getPoliceStatic().then((res) => {
      this.policeData = res
      this.initCharts()
    })
  },

  methods: {
    initCharts() {
      this.circleChart = echarts.init(document.getElementById('circleChart'))

      // 数据初始化
      const initOption = this.initChartOption()
      this.circleChart.setOption(initOption)
    },

    initChartOption() {
      let initOption = deepClone(option)

      initOption.legend.data = this.policeData.map(i => i.name)
      initOption.series[0].data = this.policeData
      return initOption
    }
  }
}
</script>

<style lang="scss" scoped>
#circleChart {
  width: 400px;
  min-height: 200px;
  height: calc((100vh - 46px) * 0.35 - 66px);
}
</style>
