<template>
  <div id="centerAMap"></div>
</template>

<script>
import AMap from 'AMap'
import { getCompanyPage, getCurrentFireCenterMap } from '@/api/PlanDrill'

const sts = [
  {
    url: 'https://a.amap.com/jsapi_demos/static/images/blue.png',
    size: new AMap.Size(32, 32),
    offset: new AMap.Pixel(-16, -16)
  },
  {
    url: 'https://a.amap.com/jsapi_demos/static/images/green.png',
    size: new AMap.Size(32, 32),
    offset: new AMap.Pixel(-16, -16)
  },
  {
    url: 'https://a.amap.com/jsapi_demos/static/images/orange.png',
    size: new AMap.Size(36, 36),
    offset: new AMap.Pixel(-18, -18)
  },
  {
    url: 'https://a.amap.com/jsapi_demos/static/images/red.png',
    size: new AMap.Size(48, 48),
    offset: new AMap.Pixel(-24, -24)
  },
  {
    url: 'https://a.amap.com/jsapi_demos/static/images/darkRed.png',
    size: new AMap.Size(48, 48),
    offset: new AMap.Pixel(-24, -24)
  }
]

export default {
  mounted() {
    this.$nextTick(() => {
      this.initMap()
    })
  },

  methods: {
    initMap() {
      this.mapControl = new AMap.Map('centerAMap', {
        resizeEnable: true, //是否监控地图容器尺寸变化
        mapStyle: 'amap://styles/grey',
        viewMode: '3D'
      })

      this.fetchCompanyLists()
    },

    // 获取全部单位, 以供选择筛选
    fetchCompanyLists() {
      return getCompanyPage({
        current: 1,
        size: 1000000
      }).then((data) => {
        const lists = data.records.filter((item) => {
          return item.latitude && item.longitude
        })

        this.drawMarkers(lists)
      })
    },

    generateContent(name) {
      return `
        <div style="
          color: #fff;
          font-size: 12px;
          box-sizing: border-box;
          padding: 8px;
          background-color: rgba(1, 18, 38, 0.6);
        ">
        ${name}
        </div>
      `
    },

    drawMarkers(lists) {
      const markers = []
      lists.forEach((item) => {
        const marker = new AMap.Marker({
          position: new AMap.LngLat(item.longitude, item.latitude),
          icon: require('@/assets/images/home/unit.png')
        })

        marker.setTitle(item.name)
        marker.setLabel({
          direction: 'top',
          content: this.generateContent(item.name)
        })

        markers.push(marker)
      })

      this.mapControl.plugin(['AMap.MarkerClusterer'], () => {
        new AMap.MarkerClusterer(this.mapControl, markers, {
          styles: sts,
          gridSize: 80
        })
      })

      this.checkBarrio()
    },

    // 绘制行政区域
    checkBarrio() {
      getCurrentFireCenterMap().then(({ provinceName, regionName }) => {
        this.mapControl.plugin(['AMap.DistrictSearch'], () => {
          const district = new AMap.DistrictSearch({
            level: provinceName,
            showbiz: false,
            extensions: 'all',
            subdistrict: 0
          })

          district.search(regionName, (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
              this.drawBarrio(result.districtList[0].boundaries)
            }
          })
        })
      })
    },

    drawBarrio(bounds) {
      const map = this.mapControl
      if (bounds) {
        for (let i = 0, l = bounds.length; i < l; i++) {
          new AMap.Polygon({
            map,
            strokeWeight: 1,
            strokeColor: '#18cffd',
            fillColor: '#80d8ff',
            fillOpacity: 0.2,
            path: bounds[i]
          })
        }

        //地图自适应
        map.setFitView()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#centerAMap {
  width: 100vw;
  height: 100vh;

  &::v-deep .amap-marker-label {
    border: none;
    background-color: transparent;
  }
}
</style>
