import { GET, POST } from '@/utils/http'

const HOST = '/service-fire'
const SOCIALIZE_HOST = '/service-socialize-v2/socialize'

// 建筑类型维度
export function getDimBuildTypeList(params) {
  return GET({ url: `${HOST}/fireDim/dimBuildType`, params })
}

// 获取单位下所属建筑物
export function getListIncludeSelfBuild(params) {
  return GET({ url: `${SOCIALIZE_HOST}/build/listIncludeSelfBuild`, params })
}

// 首页获取重点单位分页列表
export function getCompanyPage(params) {
  return POST({ url: '/service-socialize-v2/socializeCompanyInfo/CompanyPage', params })
}

export function getCurrentFireCenterMap(params) {
  return GET({ url: `${HOST}/fireOrgan/getOrganInfo`, params })
}

export default {
  getCompanyPage,
  getDimBuildTypeList,
  getListIncludeSelfBuild,
  getCurrentFireCenterMap
}
