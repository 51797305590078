<template>
  <div class="unitchart-container">
    <div v-show="chartEmpty" ref="unitChart" id="unitChart" class="loading-transparent" v-loading="loading"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { deepClone } from '@/utils/util'
import { getManageStatic } from '@/api/homePage'
import testPic from '@/assets/images/test.png'

const option = {
  grid: {
    left: '10%',
    top: '10%',
    right: '0',
    bottom: '10%'
  },

  xAxis: {
    type: 'category',
    axisTick: { show: false },
    axisLine: { lineStyle: { color: '#18cffd' } },
    axisLabel: {
      color: '#18cffd',
      interval: 0,
      overflow: 'truncate'
    },
    data: []
  },

  yAxis: {
    type: 'value',
    name: '管辖单位（家）',
    splitLine: { lineStyle: { color: '#1077BA' } },
    nameTextStyle: { align: 'left' },
    axisLabel: { color: '#18cffd' },
    axisLine: { lineStyle: { color: '#18cffd' } }
  },

  series: [
    {
      data: undefined,
      type: 'pictorialBar',
      symbol: 'rect ',
      barWidth: 12,
      itemStyle: {
        normal: {
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#18CFFD',
              fontSize: 12
            }
          },
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(24, 207, 253, 0.2)' },
            { offset: 1, color: '#18cffd' }
          ])
        }
      }
    }
  ]
}

export default {
  data() {
    return {
      testPic,
      chartEmpty: true,
      loading: false
    }
  },

  mounted() {
    this.getManageStatic().then(() => {
      this.initCharts()
    })
  },

  methods: {
    getManageStatic() {
      return getManageStatic().then((res) => {
        this.xAxis = []
        this.yAxis = []
        res.forEach((item) => {
          this.xAxis.push(item.name)
          this.yAxis.push(item.number)
        })
      })
    },

    initCharts() {
      this.unitChart = echarts.init(document.getElementById('unitChart'))
      const initOption = this.initChartOption()
      this.unitChart.setOption(initOption)
    },

    initChartOption() {
      const initOption = deepClone(option)
      initOption.series[0].data = this.yAxis.map((i) => {
        return {
          value: i,
          Symbol: testPic
        }
      })
      initOption.xAxis.data = this.xAxis
      initOption.xAxis.axisLabel.width = 400 / this.xAxis.length
      return initOption
    }
  }
}
</script>

<style lang="scss" scoped>
#unitChart {
  width: 400px;
  min-height: 280px;
  height: calc((100vh - 46px) * 0.45 - 66px);
}
</style>
