<template>
  <div class="title" :class="{ 'title-reverse': reverse }">
    <img v-if="!reverse" src="@/assets/images/home-page-drawer/title-icon.png" />
    {{ title }}
    <img class="icon-right" v-if="reverse" src="@/assets/images/home-page-drawer/title-icon-right.png" />
    <div class="border-bottom" :class="{ reverse }"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    reverse: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.title {
  color: #18cffd;
  font-size: 16px;
  // padding-bottom: 5px;
  border-bottom: 4px solid transparent;
  // border-image: url("~@/assets/images/home-page-drawer/title-border.png");
  // transparent
  margin-bottom: 30px;

  &.title-reverse {
    text-align: right;
  }
  img {
    // width:
  }
}
.border-bottom {
  width: 100%;
  height: 4px;
  margin-top: 5px;
  background: url('~@/assets/images/home-page-drawer/title-border.png') no-repeat;
  &.reverse {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    /*IE*/
    filter: FlipH;
  }
}
</style>
